/* stylelint-disable selector-class-pattern */
@use "_variables";
@use "_mediaQueries";

.carousel {
  padding: variables.$base-factor * 4;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;

  & > * {
    padding-bottom: variables.$base-factor * 12;
  }

  :global {
    .carousel-slider {
      box-sizing: border-box !important;
      overflow: visible !important;

      .slider-wrapper {
        overflow: visible !important;

        .slider {
          li.slide {
            opacity: 0.5;
            transform: scale(0.95);
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

            &.selected {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }
    }

    .carousel .control-dots .dot {
      background-color: variables.$white117;
      box-shadow: none;

      &.selected {
        background-color: variables.$purple100;
        border: 1px solid variables.$purple110;
      }
    }
  }
}
