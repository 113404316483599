@use "_variables.scss";
@use "_textStyles";
@use "_specifics";
@use "_utils";
@use "_mediaQueries";

.collapse {
  display: flex;
  flex-direction: column;
  color: variables.$black100;
  width: 100%;
  height: fit-content;
  background-color: variables.$white102;
  border-radius: variables.$border-radius2;
  position: relative;
  overflow: hidden;

  &Button {
    @include utils.allUnset;
    @include textStyles.button;

    font-family: var(--font-inter);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: variables.$base-factor * 2;
    cursor: pointer;
    width: 100%;
    padding: variables.$base-factor * 4 variables.$base-factor * 5;

    svg {
      stroke: variables.$white127;
      transition: transform 300ms ease-in-out;

      path {
        transition: transform 300ms ease-in-out;
        transform-origin: center;
      }
    }
  }

  &Wrapper {
    transition: height 300ms ease-in-out;
  }

  &Content {
    padding: variables.$base-factor * 4 variables.$base-factor * 5;
    padding-top: variables.$base-factor * 1;
    color: variables.$black70;
  }

  &Opened {
    .collapseButton {
      svg {
        transform: rotate(180deg);

        path:last-child {
          transform: scale(0);
        }
      }
    }
  }
}
