@use "_textStyles";
@use "_utils";
@use "_variables";
@use "_mediaQueries";

.partnersSection {
  z-index: 2;
  position: relative;
  text-align: center;
  padding-top: variables.$base-factor * 5;
  padding-bottom: variables.$base-factor * 15;
  background-color: variables.$white100;

  iframe {
    margin-top: variables.$base-factor * 10;
    border-radius: variables.$border-radius3;
    border: 3px solid variables.$white104;

    @include mediaQueries.md(min-width) {
      width: 610px;
      height: 343px;
    }

    @include mediaQueries.md(max-width) {
      width: 90%;
      aspect-ratio: 16/9 !important;
    }
  }

  &Row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: variables.$base-factor * 20;

    @include mediaQueries.md(max-width) {
      justify-content: space-between;
      gap: 0;
      width: 90%;
      margin: 0 auto;
    }

    &Item {
      @include utils.allUnset;

      cursor: pointer;

      &:hover {
        img {
          filter: grayscale(0);
        }
      }

      img {
        filter: grayscale(100%);
        transition: filter 0.15s ease-out;
        width: auto;
      }

      &.bpiLogo {
        img {
          height: 30px;

          @include mediaQueries.lg(max-width) {
            height: 25px;
          }

          @include mediaQueries.md(max-width) {
            height: 22px;
          }

          @include mediaQueries.sm(max-width) {
            height: 18px;
          }

          @include mediaQueries.xs(max-width) {
            height: 15px;
          }
        }
      }

      &.frstLogo {
        img {
          height: 51px;

          @include mediaQueries.lg(max-width) {
            height: 45px;
          }

          @include mediaQueries.md(max-width) {
            height: 37px;
          }

          @include mediaQueries.sm(max-width) {
            height: 31px;
          }

          @include mediaQueries.xs(max-width) {
            height: 25px;
          }
        }
      }

      &.fabricLogo {
        img {
          height: 20px;

          @include mediaQueries.lg(max-width) {
            height: 15px;
          }

          @include mediaQueries.md(max-width) {
            height: 13px;
          }

          @include mediaQueries.sm(max-width) {
            height: 11px;
            margin-bottom: 2px;
          }

          @include mediaQueries.xs(max-width) {
            height: 9px;
          }
        }
      }

      &.mobulaLogo {
        img {
          height: 27px;
          margin-bottom: 2px;

          @include mediaQueries.lg(max-width) {
            height: 20px;
          }

          @include mediaQueries.md(max-width) {
            height: 17px;
          }

          @include mediaQueries.sm(max-width) {
            height: 15px;
          }

          @include mediaQueries.xs(max-width) {
            height: 13px;
          }
        }
      }

      &.zokyoLogo {
        img {
          height: 27px;
          margin-top: 2px;

          @include mediaQueries.lg(max-width) {
            height: 20px;
          }

          @include mediaQueries.md(max-width) {
            height: 17px;
          }

          @include mediaQueries.sm(max-width) {
            height: 15px;
          }

          @include mediaQueries.xs(max-width) {
            height: 13px;
          }
        }
      }
    }
  }

  &Label {
    color: variables.$black50;
    margin-top: variables.$base-factor * 4;
    text-align: center;

    @include mediaQueries.md(max-width) {
      margin-top: variables.$base-factor * 4;
    }

    @include mediaQueries.sm(max-width) {
      margin-top: variables.$base-factor * 3;
    }

    @include mediaQueries.xs(max-width) {
      margin-top: variables.$base-factor * 2;
    }
  }
}
