@use "_variables";
@use "_textStyles";
@use "_mediaQueries";

.customImage {
  display: inline-flex;
  flex-direction: column;

  p {
    text-align: center;
    font-size: 12px;
    color: variables.$white127;
  }

  &Container {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid variables.$white107;
    overflow: hidden;
    border-radius: variables.$border-radius2;
  }
}
