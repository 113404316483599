@use "_variables";
@use "_mediaQueries";
@use "_animations";
@use "_textStyles";

.heroSection {
  position: relative;
  height: 100vh;
  transform-style: preserve-3d;
}

@keyframes coverAppear {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.heroCoverVideo {
  @include animations.animate(coverAppear, 1s, $timing-function: ease-out);

  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100%;
  z-index: 0;
  outline: none;
  transform: translateZ(-100px) scale(1.5);
  object-fit: cover;
  object-position: center;

  &Large {
    @include mediaQueries.md(max-width) {
      display: none;
    }
  }

  &Small {
    margin-top: -60px;
    min-height: 60%;
    height: 60%;
    object-position: top center;
    top: 0;

    @include mediaQueries.md(min-width) {
      display: none;
    }
  }
}

.heroCoverImage {
  @include animations.animate(coverAppear, 1s, $timing-function: ease-out);

  position: absolute;
  object-fit: cover;
  object-position: center center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transform: translateZ(-100px) scale(1.5);

  &Large {
    @include mediaQueries.md(max-width) {
      display: none;
    }
  }

  &Small {
    @include mediaQueries.md(min-width) {
      display: none;
    }
  }

  @include mediaQueries.md(max-width) {
    margin-top: -200px;
  }

  @include mediaQueries.sm(max-width) {
    margin-top: -50%;
  }
}

.heroSectionGradientWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.heroSectionGradient {
  height: 100px;
  width: 100%;
  background: variables.$white100;
  background: linear-gradient(180deg, rgba(137, 139, 255, 0%) 0%, variables.$white100 70%);

  @include mediaQueries.md(max-width) {
    height: 400px;
  }

  @include mediaQueries.sm(max-width) {
    height: calc(200px + 30%);
  }
}

.heroSectionContent {
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding-bottom: variables.$base-factor * 40;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  gap: variables.$base-factor * 5;

  @include mediaQueries.sm(max-width) {
    padding-bottom: variables.$base-factor * 15;
  }

  @include animations.animate(coverAppear, 1s, $timing-function: ease-out);

  &Main {
    display: inline-block;
    max-width: 600px;

    &News {
      $height: 26px;

      margin-bottom: variables.$base-factor * 2;
      font-size: 12px;
      min-height: $height;
      max-height: $height;
      background-color: variables.$black40;
      color: variables.$white100;
      padding: variables.$base-factor * 2 variables.$base-factor * 4;

      svg {
        width: 12px;
        stroke: variables.$white102;
      }

      &ReadMore {
        color: variables.$white101;
        margin-left: variables.$base-factor * 2;
        opacity: 0.5;

        span {
          margin-right: variables.$base-factor * 1;
        }
      }

      &:hover {
        transform: none;
        background-color: variables.$black60;

        * {
          transform: none !important;
        }
      }
    }

    h1 {
      display: inline-block;
    }

    p {
      display: flex;
      margin-top: variables.$base-factor * 2;
      font-size: 20px;
      color: variables.$black80;
      word-wrap: break-word;
    }
  }
}

.heroSectionContentMetricsWrapper {
  font-size: 16px;
  display: flex;
  align-items: center;

  & > div:last-child {
    & > strong {
      color: variables.$black100;
      font-weight: 400;
      margin-right: variables.$base-factor;
    }

    & > span {
      color: variables.$black80;
    }
  }
}

@keyframes growAndFade {
  0% {
    opacity: 0.25;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(4);
  }
}

.heroSectionContentMetricsDot {
  position: relative;
  display: inline-block;
  margin-right: variables.$base-factor * 2;

  &Circle {
    height: variables.$base-factor * 1.5;
    width: variables.$base-factor * 1.5;
    background-color: variables.$purple100;
    border-radius: 50%;

    &Ripple {
      animation: growAndFade 3s infinite ease-out;
      background-color: variables.$purple100;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
}

.heroSectionContentButtonsWrapper {
  display: flex;
  align-items: center;
  gap: variables.$base-factor * 4;

  & > *:last-child {
    border: 2px solid variables.$white117;
  }
}
