@use "_variables";
@use "_textStyles";
@use "_mediaQueries";

.faqSection {
  background-color: variables.$white100;
  z-index: 1;
  padding: variables.$base-factor * 20 0;
  border-top: 1px solid variables.$white107;

  &Container {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: variables.$base-factor * 4;

    @include mediaQueries.md(max-width) {
      grid-template-columns: 1fr;
    }
  }

  &Header {
    & > *:first-child {
      margin-bottom: variables.$base-factor * 2;
    }
  }

  &CollapseContainer {
    display: flex;
    flex-direction: column;
    gap: variables.$base-factor * 2;
  }

  &CollapseButtonContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: variables.$base-factor * 2;
    width: 100%;

    h3 {
      font-family: monospace;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.15px;
      color: variables.$black100;
    }

    svg {
      width: variables.$base-factor * 5;
      height: variables.$base-factor * 5;
    }
  }

  p {
    font-size: 14px;
  }

  a {
    text-decoration: underline;
    transition: color 0.1s ease-in-out;

    &:hover {
      text-decoration: underline;
      color: variables.$purple100;
    }
  }
}
