/* stylelint-disable selector-class-pattern */
@use "_textStyles";
@use "_utils";
@use "_variables";
@use "_mediaQueries";
@use "_animations";

$base-transition-delay: 0.25s;

.introSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: variables.$base-factor * 5 0;
  background-color: variables.$white101;
  gap: variables.$base-factor * 30;
  border-top: 1px solid variables.$white102;
  border-bottom: 1px solid variables.$white102;

  &Animation svg {
    @include mediaQueries.md(max-width) {
      margin-left: variables.$base-factor * 2;
    }
  }

  @include mediaQueries.xl(max-width) {
    gap: variables.$base-factor * 15;
  }

  @include mediaQueries.lg(max-width) {
    padding-top: variables.$base-factor * 12;
    padding-bottom: 0;
    flex-direction: column-reverse;
    gap: 0;
  }

  @include mediaQueries.md(max-width) {
    gap: variables.$base-factor * 10;
  }

  & > div:first-child {
    position: relative;
    display: flex;
    align-self: center;

    .introSectionAnimation {
      width: variables.$base-factor * 116;

      @include mediaQueries.md(max-width) {
        width: variables.$base-factor * 90;
      }
    }
  }

  p {
    color: variables.$black80;
  }

  & > div:last-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: variables.$base-factor * 3;
    height: 100%;

    @include mediaQueries.md(min-width) {
      width: variables.$base-factor * 106;
    }

    @include mediaQueries.lg(max-width) {
      align-items: center;
      text-align: center;
    }

    @include mediaQueries.md(max-width) {
      width: auto;
      padding-left: variables.$base-factor * 8;
      padding-right: variables.$base-factor * 8;
      gap: variables.$base-factor * 4;
    }

    h2,
    :global(.tb-tag),
    p,
    .introSectionButton {
      opacity: 0;
    }

    &.introSectionContentAppear {
      :global(.tb-tag) {
        @include animations.animate(fadeInUp, 1s, $timing-function: ease-out);
      }

      h2 {
        @include animations.animate(fadeInUp, 1s, $base-transition-delay, $timing-function: ease-out);
      }

      p {
        @include animations.animate(fadeInUp, 1s, $base-transition-delay * 2, $timing-function: ease-out);
      }

      .introSectionButton {
        @include animations.animate(fadeInUp, 1s, $base-transition-delay * 3, $timing-function: ease-out);
      }
    }
  }

  .introSectionButton {
    &:hover {
      & > * {
        transform: none !important;
      }
    }
  }
}

.introSectionTag {
  color: variables.$purple100;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: variables.$base-factor * 1;
  border-radius: variables.$base-factor * 2;

  svg {
    width: variables.$base-factor * 4;
    height: variables.$base-factor * 4;
    color: variables.$purple100;
  }
}
