@use "_variables";
@use "_textStyles";
@use "_mediaQueries";

.blogPostCard {
  display: inline-flex;
  flex-direction: column;
  gap: variables.$base-factor * 5;
  width: 100%;
  border-radius: variables.$border-radius2;
  background-color: variables.$white101;
  padding-bottom: variables.$base-factor * 5;
  border: 1px solid variables.$white107;
  overflow: hidden;

  &:hover {
    .blogPostCardImage {
      & > div {
        & > img {
          transform: scale(1.1);
        }
      }
    }
  }

  p,
  h2 {
    padding: 0 variables.$base-factor * 5;
  }

  &Image {
    position: relative;
    width: 100%;
    height: 200px !important;

    & > div {
      height: 100%;
      border-radius: 0 !important;
      border: 0 !important;

      & > img {
        transition: transform 200ms ease-out;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 22px;

    @include mediaQueries.xl(max-width) {
      font-size: 18px;
    }

    @include mediaQueries.lg(max-width) {
      font-size: 14px;
    }
  }

  &Description {
    color: variables.$white127;
  }

  &Info {
    @include textStyles.tag;

    color: variables.$white117;
  }
}
