/* stylelint-disable selector-class-pattern */
@use "_variables";
@use "_textStyles";
@use "_animations";
@use "_specifics";
@use "_mediaQueries";
@use "_utils";

.networkSection {
  background-color: variables.$white100;
  z-index: 1;

  & > div {
    margin-top: variables.$base-factor * 20;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: variables.$base-factor * 20;
    padding-bottom: variables.$base-factor * 20;

    @include mediaQueries.lg(max-width) {
      flex-direction: column;
    }

    @include mediaQueries.md(max-width) {
      gap: variables.$base-factor * 10;
      margin-top: variables.$base-factor * 10;
    }

    & > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h2 {
          margin-top: variables.$base-factor * 2;
          margin-bottom: variables.$base-factor * 4;
          opacity: 0;
        }

        p {
          margin-bottom: variables.$base-factor * 4;
        }

        :global(.tb-tag) {
          opacity: 0;
        }

        &.networkSectionHeadingAppear {
          :global(.tb-tag) {
            @include animations.animate(fadeInUp, 1s, $timing-function: ease-out);
          }

          h2 {
            @include animations.animate(fadeInUp, 1s, 0.25s, $timing-function: ease-out);
          }
        }
      }
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: variables.$base-factor * 5;

    @include mediaQueries.sm(max-width) {
      width: 100%;
    }
  }

  &More {
    transform: scale(0.8) !important;
    color: variables.$black60 !important;
    border: 1px solid variables.$white107 !important;
    background-color: variables.$white101;

    & > * {
      transform: none !important;
    }
  }
}

.networkSectionTweetCardsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: variables.$base-factor * 4;

  @include mediaQueries.lg(max-width) {
    grid-template-columns: 1fr 1fr;
    place-content: center;
    align-items: center;
    justify-content: center;
  }

  @include mediaQueries.sm(max-width) {
    grid-template-columns: 1fr;
    place-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > .tweetCard:nth-child(4),
    & > .tweetCard:nth-child(5),
    & > .tweetCard:last-child {
      display: none;
    }
  }
}

.networkSectionPartners {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: variables.$base-factor * 4;
  width: 100%;

  &Item {
    overflow: hidden;
    border-radius: variables.$border-radius3;
    border: 1px solid variables.$white107;
    aspect-ratio: 1 / 1;
    opacity: 0.5;
    transition: opacity 0.2s ease-out, filter 0.2s ease-out;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }

    &:hover {
      opacity: 1;
      filter: grayscale(0%);
    }
  }
}

.tweetCard {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border-radius: variables.$border-radius3;
  border: 1px solid variables.$white107;
  gap: variables.$base-factor * 5;
  padding: variables.$base-factor * 5;
  box-sizing: border-box;
  background-color: variables.$white101;
  z-index: 1;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    opacity: 1;
  }

  @include mediaQueries.sm(max-width) {
    width: 100%;
  }

  h3 {
    font-weight: 500;
    color: variables.$black100;
  }

  span {
    color: variables.$black50;
    font-size: 12px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: variables.$base-factor * 3;

    img {
      width: variables.$base-factor * 16;
      height: variables.$base-factor * 16;
      border-radius: variables.$border-radius3;
      transition: filter 0.3s ease-in-out;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: variables.$base-factor * 2;
    }
  }

  p {
    flex: 1;
    color: variables.$black80;
  }
}
