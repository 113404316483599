@use "_variables";
@use "_textStyles";
@use "_mediaQueries";

.blogSection {
  border-top: 1px solid variables.$white107;
  background-color: variables.$white101;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: variables.$base-factor * 20;
  padding-bottom: variables.$base-factor * 10;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: variables.$base-factor * 4;
    text-align: center;
  }

  &Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: variables.$base-factor * 4;
  }

  &Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: variables.$base-factor * 2;
    text-align: center;
  }

  &Carousel {
    padding: variables.$base-factor * 4;
    border-radius: variables.$border-radius2;
  }

  &Post {
    background-color: variables.$white100;
    text-align: left;
    max-width: 600px;

    &Container {
      display: inline-block;
    }
  }
}
